// Base
import styled, { css } from 'styled-components'
import { WithThemeProp } from '@tblg/components'

interface Props {
  show: boolean,
}

// Style
export const Panel = styled.article<Props & WithThemeProp>`
  background-color: white;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 0 1em 0 rgba(82, 63, 105, 0.05);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 2rem;
  top: 0;
  width: 30rem;
  transform: translateY(0);
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
  opacity: 1;
  z-index: 1;

  /* mobile view */
  @media (max-width: ${ props => props.theme.breakpoints.md }) {
    width: 100%;
    right: 0;
  }

  ${ props => !props.show && css`
    transform: translateY(2rem);
    opacity: 0;
    /* move back so it doesn't cover any functionality */
    z-index: -1;
    transition:
      transform 0.3s ease,
      opacity 0.3s ease;
  `}
`

export const PanelBody = styled.section<WithThemeProp>`
  & > div {
    border-bottom: 1px solid ${ props => props.theme.colors.userMenuBorders };
  }
`

export const PanelFooter = styled.section<WithThemeProp>`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  border-top: 1px solid ${ props => props.theme.colors.userMenuBorders };
`

export const PanelHeader = styled.section<WithThemeProp>`
  padding: 1.5rem;
  background-color: ${ props => props.theme.colors.topMenuButtonTextHover };
  color: white;
`
