import { DomainModel } from '@models/domain/interfaces/DomainModel'
import { ResourceFilter } from '@models/utility/ResourceFilter'

export abstract class RelationInterface<T extends DomainModel<T>> {
    public key: string
    public model: () => T
    public filter?: ResourceFilter<T>
    public eagerLoad: boolean
    public isAliasFor?: string

    constructor(
        key: string,
        model: () => T,
        filter?: ResourceFilter<T>,
        eagerLoad: boolean = true,
        isAliasFor?: string,
    ) {
        this.key = key
        this.model = model
        this.filter = filter
        this.eagerLoad = eagerLoad
        this.isAliasFor = isAliasFor
    }
}
