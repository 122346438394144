import { Type } from '@models/domain/interfaces/Attribute'
import { Props } from './Props'
import { Connection } from '@services/Connection/Interfaces'
import { QueueStatusRepository } from '@repositories/QueueStatusRepository'
import { DomainModel } from '@models/domain/interfaces/DomainModel'
import { RepositoryInterface } from '@repositories/interfaces/Repository'

export class QueueStatus extends DomainModel<QueueStatus> {
    public readonly classIdentifier = 'QueueStatus'
    public readonly localeNamespace = 'queue_status'

    public readonly attributes = [
        {
            key: 'amountOfMessagesInQueue',
            type: Type.INTEGER,
        },
        {
            key: 'oldestMessage',
            type: Type.DATE,
        },
    ]

    public amountOfMessagesInQueue!: number
    public oldestMessage?: string

    constructor(props?: Props) {
        super()
        Object.assign(this, props)
    }

    public readonly repository: (connection: Connection) => RepositoryInterface<QueueStatus>
        =  (connection: Connection) => new QueueStatusRepository(connection)

}
