import React from 'react'
import * as C from './Style'

interface Props {
  updateMinimized: () => void,
  minimized: boolean,
}

const TopBar: React.FC<Props> = ({
  updateMinimized,
  minimized,
}) => {
  return (
    <C.TopBar minimized={ minimized }>
      <C.BrandTitle>leaseDoc</C.BrandTitle>
      <C.Hamburger onClick={ updateMinimized }>
        <C.Bar />
      </C.Hamburger>
    </C.TopBar>
  )
}

export default TopBar
