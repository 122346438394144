import styled from 'styled-components'
import { WithThemeProp } from '@tblg/components'

export const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    height: 100%;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
`

export const Main = styled.main<WithThemeProp>`
    background-color: ${ props => props.theme.colors.gray };
    flex-grow: 1;
    overflow: auto;
    padding: 25px;
`

export const Footer = styled.footer<WithThemeProp>`
    display: flex;
    flex-direction: row;
    color: ${ props => props.theme.colors.textLight };
    font-weight: normal;
    padding: 20px 25px;
    p {
        margin: 0;
    }
`

export const QueueStatusIndicatorWrapper = styled.div`
  margin-left: auto;
`
