import { FC } from 'react'
import { useRouter } from 'next/router'
import * as C from './Style'
import { WithT } from 'i18next'
import { WithTranslation, withTranslation } from 'next-i18next'
import { MenuContext, MenuOrientation, Menu } from '@tblg/components'
import FlagNL from '@static/svgs/flag-nl.svg'
import FlagEN from '@static/svgs/flag-en.svg'

interface Props {
  show: boolean,
}

const LanguageSelect: FC<Props & WithT & WithTranslation> = ({ show, t }) => {
  const router = useRouter()

  const MENU_ITEMS = [
    {
        svg: FlagNL,
        title: t('nl'),
        onClick: () => router.push(router.pathname, router.asPath, { locale: 'nl' }),
    },
    {
        svg: FlagEN,
        title: t('en'),
        onClick: () => router.push(router.pathname, router.asPath, { locale: 'en' }),
    },
  ]

  return (
    <C.Panel id="language-switch-menu" show={ show }>
      <C.PanelBody>
        <Menu
          context={ MenuContext.userMenu }
          items={ MENU_ITEMS }
          orientation={ MenuOrientation.vertical }
        />
      </C.PanelBody>
    </C.Panel>
  )
}

export default withTranslation('locales')(LanguageSelect)
