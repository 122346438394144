import { RelationInterface } from '@models/domain/interfaces/relations/Interface'
import { Connection } from '@services/Connection/Interfaces'
import { BelongsTo } from '@models/domain/interfaces/relations/BelongsTo'
import { BelongsToMany } from '@models/domain/interfaces/relations/BelongsToMany'
import { Attribute, Type } from '@models/domain/interfaces/Attribute'
import { RepositoryInterface } from '@repositories/interfaces/Repository'

// tslint:disable-next-line:no-any
export abstract class DomainModel<T extends DomainModel<any>> {
    // tslint:disable-next-line:no-any
    [key: string]: any

    get label(): string {
        return this[this.labelAttribute]
    }

    get optionValue(): string {
        return this[this.idAttribute]
    }

    get shorthandId(): string {
        const path = this[this.idAttribute] as string
        const i = path.lastIndexOf('/')
        return i >= 0 ? path.slice(i + 1) : path
    }

    get isNew(): boolean {
        return this[this.idAttribute] === undefined
    }

    public abstract readonly classIdentifier: string
    public readonly idAttribute: keyof this = 'id'
    public readonly labelAttribute: keyof this = 'id'
    public readonly localeNamespace!: string
    // tslint:disable-next-line:no-any
    public readonly relations: Array<RelationInterface<any>> = []
    public readonly attributes: Array<Attribute<T>> = []

    public id?: string

    public abstract readonly repository: (connection: Connection) => RepositoryInterface<T>

    public getLocaleNamespaces(alreadyLoaded: Array<DomainModel<T>> = []): string[] {
        if (alreadyLoaded.map(loadedModel => loadedModel.classIdentifier).includes(this.classIdentifier)) {
            return []
        }
        const namespaces = [this.localeNamespace]
            .concat(this.relations.map(relation => relation.model().getLocaleNamespaces(alreadyLoaded.concat([this])))
                .reduce((array, newsubarray) => array.concat(newsubarray), []))
        return namespaces.filter((namespace, index) => namespaces.indexOf(namespace) === index)
    }

    public getRequestData() {
        // get attributes
        const data = {} as unknown as this
        this.attributes.forEach(attribute => {
            let value =  this[attribute.key]
            if (attribute.type === Type.BOOLEAN) {
                value = !!value
            }
            // explicitely send null values instead of undefined so that attribute takes empty value (backend doesn't
            // update attributes that are not in request data)
            if (value === undefined) {
                value = null
            }
            Object.assign(data, {
                [attribute.key]: value,
            })
        })

        // get relations
        this.relations.forEach(relation => {
            if (relation instanceof BelongsTo) {
                const relatedModel = this[relation.key]
                if (relatedModel && relatedModel instanceof DomainModel) {
                    Object.assign(data, {
                        [relation.key]: relatedModel[relatedModel.idAttribute],
                    })
                } else if (relatedModel) {
                    Object.assign(data, {
                        [relation.key]: relatedModel,
                    })
                } else {
                    // explicitely send null values instead of undefined so that relation takes empty value (backend
                    // doesn't update attributes that are not in request data)
                    Object.assign(data, {
                        [relation.key]: null,
                    })
                }
            } else if (relation instanceof BelongsToMany) {
                const relatedModels = this[relation.key]
                if (relatedModels && Array.isArray(relatedModels)) {
                    Object.assign(data, {
                        [relation.key]: relatedModels.map((relatedModel: DomainModel<T> | string | number) => {
                            if (relatedModel instanceof DomainModel) {
                                return relatedModel[relatedModel.idAttribute]
                            }
                            return relatedModel
                        }),
                    })
                }
            }
        })

        // never send own id
        delete data[this.idAttribute]
        return data
    }

    public isRelation(key: string | keyof this): boolean {
        return this.relations.find(r => r.key === key) !== undefined
    }

    // tslint:disable-next-line:no-any
    public getRelation(key: string | keyof this): RelationInterface<any> {
        const relation = this.relations.find(r => r.key === key)
        if (!relation) {
            throw new Error(`Invalid relation key: ${key}`)
        }
        return relation
    }

    public isAttribute(key: string | keyof this): boolean {
        return this.attributes.find(a => a.key === key) !== undefined
    }

    public getAttribute(key: string | keyof this): Attribute<T> {
        const attribute = this.attributes.find(a => a.key === key)
        if (!attribute) {
            throw new Error(`Invalid attribute key: ${key}`)
        }
        return attribute
    }

    public async onCreate(connection: Connection): Promise<this> {
        return this.onSave(connection)
    }

    public async onUpdate(connection: Connection): Promise<this> {
        return this.onSave(connection)
    }

    public async onDelete(_0: Connection): Promise<this> {
        return this
    }

    protected async onSave(_0: Connection): Promise<this> {
        return this
    }
}
