import { Icon, Profile, TimeStamp } from '@tblg/components'
import UserMenu from './components/UserMenu'
import LanguageSelect from './components/LanguageSelect'
import { WithT } from 'i18next'
import { useTranslation, withTranslation, WithTranslation } from 'next-i18next'
import { FC, useContext, useState } from 'react'
import onClickOutside from 'react-onclickoutside'
import {
  Date as StyledDate,
  GroupWrapper,
  GroupWrapperCenter,
  LanguageSwitchButton,
  MainBar,
  NavButton,
  ProfileWrapper,
  SubBar,
  TopBarWrapper,
} from './Style'
import { FlagEN, FlagNL } from './components/LanguageSelect/Style'
import { AuthContext } from '@services/Auth'
import Router, { useRouter } from 'next/router'
import { SelectParameterSet } from './components/SelectParameterSet'
import { POLICIES } from '@constants/policies'
import { ENVIRONMENT } from '@root/environment'

const DecoratedUserMenu = onClickOutside(UserMenu)
const DecoratedLanguageSelect = onClickOutside(LanguageSelect)

interface Props {
  submenuItemsLeft?: JSX.Element,
  submenuItemsRight?: JSX.Element,
}

const TopMenu: FC<Props & WithT & WithTranslation> = ({
  submenuItemsLeft,
  submenuItemsRight,
  t,
}) => {
  const { i18n: { language } } = useTranslation()
  const auth = useContext(AuthContext)
  const router = useRouter()

  const isDashboard = router.pathname === '/'

  const [ userMenuVisibility, setUserMenuVisibility ] = useState(false)
  const [ languageSelectVisibility, setLanguageSelectVisibility ] = useState(false)

  const handleClickOutsideUserMenu = () => setUserMenuVisibility(false)
  const handleClickOutsideLanguageSelect = () => setLanguageSelectVisibility(false)

  const toggleLanguageSelectVisibility = () => setLanguageSelectVisibility(!languageSelectVisibility)
  const toggleUserMenuVisibility = () => setUserMenuVisibility(!userMenuVisibility)

  return (
    <TopBarWrapper>
      <MainBar>
        <GroupWrapper>{ auth.can(POLICIES.DEVELOPMENT) && <code>{ ENVIRONMENT.REGIONS }</code> }</GroupWrapper>
        <GroupWrapperCenter>
          { auth.can(POLICIES.FACADE_CALCULATE)  && <SelectParameterSet /> }
          <LanguageSwitchButton id="language-switch" onClick={ toggleLanguageSelectVisibility }>
            { language === 'nl' && <FlagNL /> }
            { language === 'en' && <FlagEN /> }
          </LanguageSwitchButton>
            { auth.user && (
                <ProfileWrapper onClick={ toggleUserMenuVisibility } >
                  <Profile
                      initials={ auth.user.nickname[0].toUpperCase() }
                      image={ auth.user.picture }
                      mainTitle={ auth.user.nickname }
                      subTitle={ auth.user.name }
                  />
                </ProfileWrapper>
            ) }
        </GroupWrapperCenter>
      </MainBar>
      <SubBar>
        <DecoratedUserMenu
            show={ userMenuVisibility }
            handleClickOutside={ handleClickOutsideUserMenu }
        />
        <DecoratedLanguageSelect
            show={ languageSelectVisibility }
            handleClickOutside={ handleClickOutsideLanguageSelect }
        />
        <GroupWrapper>
          <NavButton onClick={ Router.back } isDashboard={ isDashboard }>
            <Icon icon={ isDashboard ? 'home' : 'chevronLeft' }/>
            { isDashboard ? t('menu:general.dashboard') : t('common:back') as string }
          </NavButton>
          <StyledDate>
            <TimeStamp format="LL">
              { Date.now() }
            </TimeStamp>
          </StyledDate>
          { submenuItemsLeft }
        </GroupWrapper>
        <GroupWrapper>
          { submenuItemsRight }
        </GroupWrapper>
      </SubBar>
    </TopBarWrapper>
  )
}

export default withTranslation(['common', 'menu'])(TopMenu)
