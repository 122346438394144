import styled, { css } from 'styled-components'
import { WithThemeProp, IconElement } from '@tblg/components'

export const NavButton = styled.div<{ isDashboard: boolean }>`
    color: ${ props => props.theme.colors.tertiary };
    font-weight: 500;
    margin-left: 0.2rem;
    ${/* sc-sel */IconElement} {
        margin-right: 0.6rem;
    }
    
    ${ props => !props.isDashboard && css`
        cursor: pointer;
        &:hover {
            color: ${ props.theme.colors.quaternary };
        }
    ` }
`

export const TopBarWrapper = styled.header`
  left: 0;
  top: 0;
  position: relative;
  background-color: white;
`

export const GroupWrapper = styled.div`
  display: flex;
  align-items: baseline;
  > * {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
`

export const GroupWrapperCenter = styled(GroupWrapper)`
  align-items: center;
`

export const MainBar = styled.div<WithThemeProp>`
  padding: 0.75rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${ props => props.theme.colors.topMenuBorders };

  /* mobile view */
  & > ${/* sc-sel */GroupWrapper}:first-of-type {
    @media (max-width: ${ props => props.theme.breakpoints.md }) {
      display: none;
    }
  }
`

export const SubBar = styled.div<WithThemeProp>`
  padding: 0.5rem 2rem;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
  box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.08);

  /* mobile view */
  @media (max-width: ${ props => props.theme.breakpoints.md }) {
    flex-direction: column;
  }
`

export const Date = styled.p<WithThemeProp>`
  font-weight: 500;
  color: ${ props => props.theme.colors.topMenuButtonLightText };
  border-left: 1px solid ${ props => props.theme.colors.topMenuBorders };
  margin-left: 1rem;
  padding: 0 1rem;
`

export const MenuButton = styled.button<WithThemeProp>`
  border: 1px solid transparent;
  border-radius: 0.25em;
  cursor: pointer;
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  margin: 0;
  padding: 0.65em 1em;
  transition:
    color 300ms ease,
    background-color 300ms ease;
  background-color: transparent;
  color: ${ props => props.theme.colors.topMenuButtonText };
  font-weight: 500;
  font-size: 1rem;
  &:hover {
    background-color: ${ props => props.theme.colors.topMenuButtonBackgroundHover };
    color: ${ props => props.theme.colors.topMenuButtonTextHover };
  }
`

export const MenuButtonLight = styled(MenuButton)<WithThemeProp>`
  color: ${ props => props.theme.colors.topMenuButtonLightText };
`

export const ProfileWrapper = styled.div`
  margin-left: 1rem;

  &:hover {
    cursor: pointer;
  }
`

export const LanguageSwitchButton = styled.div`
  width: 1.5rem;
  margin-right: 1rem;
  cursor: pointer;

  svg {
    vertical-align: bottom;
  }
`
