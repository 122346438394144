import { FC, useContext, useEffect, useState } from 'react'
import useInterval from '@helpers/useInterval'
import { ConnectionContext } from '@services/Connection'
import { QueueStatusRepository } from '@repositories/QueueStatusRepository'
import { QueueStatus } from '@models/domain/QueueStatus'
import { WithT } from 'i18next'
import { withTranslation, WithTranslation } from 'next-i18next'
import { TrafficRate } from './Enums'
import { TrafficIcon, TechInfo, Wrapper } from './Style'
import { QUEUE_STATUS_SHOW_TECH } from '@constants/localStorageKeys'
import { RequestError } from '@services/Connection/RequestError'
import { AuthContext } from '@services/Auth'
import { POLICIES } from '@constants/policies'

const QueueStatusIndicator: FC<WithTranslation> = ({ t }: WithT) => {
  const auth = useContext(AuthContext)

  const getInitShowTech = (): boolean => {
    const localStorageValue = localStorage.getItem(QUEUE_STATUS_SHOW_TECH)
    if (localStorageValue === undefined) {
      return false
    }
    return localStorageValue === 'true'
  }

  const [showTech, setShowTech] = useState(getInitShowTech())
  const [queueStatus, setQueueStatus] = useState<QueueStatus>()
  const connection = useContext(ConnectionContext)
  const queueStatusRepository = new QueueStatusRepository(connection)
  const canViewQueueStatus = auth.can(POLICIES.DEVELOPMENT)

  useEffect(() => {
    const load = async () => {
      const newQueueStatus = await queueStatusRepository.get()
      setQueueStatus(newQueueStatus)
    }

    if (canViewQueueStatus) {
      load()
    }
  }, [])

  if (canViewQueueStatus) {
    useInterval(async () => {
      try {
        const newQueueStatus = await queueStatusRepository.get()
        setQueueStatus(newQueueStatus)
      } catch (error) {
        if (error instanceof RequestError && error.response.status === 401) {
          auth.login()
          return
        }
        throw error
      }
    }, 60 * 1000)
  }

  let trafficRate: TrafficRate = TrafficRate.normal
  if (queueStatus && queueStatus.amountOfMessagesInQueue > 500) {
    trafficRate = TrafficRate.veryHigh
  } else if (queueStatus && queueStatus.amountOfMessagesInQueue > 100) {
    trafficRate = TrafficRate.high
  }

  const toggleShowTech = () => {
    localStorage.setItem(QUEUE_STATUS_SHOW_TECH, !showTech ? 'true' : 'false')
    setShowTech(!showTech)
  }

  return (
      <Wrapper onClick={ toggleShowTech }>
        { canViewQueueStatus && queueStatus && queueStatus.amountOfMessagesInQueue > 0 && (
            <>
              <div>
                { t('common:processing_changes') }
                <TrafficIcon icon="sync" trafficRate={ trafficRate } />
              </div>
              { showTech && (
                  <>
                    <TechInfo>
                      { t('common:messages_in_queue') }: { queueStatus.amountOfMessagesInQueue }
                    </TechInfo>
                    <TechInfo>
                      { t('common:oldest_message') }: { queueStatus.oldestMessage }
                    </TechInfo>
                  </>
              ) }
            </>
        ) }
      </Wrapper>
  )
}

export default withTranslation('common')(QueueStatusIndicator)
