import { LeaseObjectType, MinimalCalculationRequest } from '@tblg/calculator-types'
import { createContext, FC, useContext, useEffect, useState } from 'react'
import { Calculator, ParameterSet } from '@services/Calculator'
import { AuthContext } from '@services/Auth'
import { CarCategory } from '@tblg/calculator-js-client'

interface Context {
    parameterSets: ParameterSet[]
    setParameterSets: (parameterSets: ParameterSet[]) => void,
    request: Partial<MinimalCalculationRequest>,
    setRequest: (calculationRequest: Partial<MinimalCalculationRequest>) => void,
    ready: boolean,
}

export const PrepareCalculatorContext = createContext<Context>({
    parameterSets: [],
    setParameterSets: () => {
        throw new Error('No context provider in current tree')
    },
    request: {},
    setRequest: () => {
        throw new Error('No context provider in current tree')
    },
    ready: false,
})

export const leaseObjectTypeToCarCategory = (type: LeaseObjectType): CarCategory => {
    switch (type) {
        case LeaseObjectType.New:
            return CarCategory.STOCK
        case LeaseObjectType.Configurable:
            return CarCategory.NEW
        case LeaseObjectType.Occasion:
            return CarCategory.OCCASION
        default:
            return CarCategory.NEW
    }
}

export const usePrepareCalculator = (): Context => {
    const [parameterSets, setParameterSets] = useState<ParameterSet[]>([])
    const [request, setRequest] = useState<Partial<MinimalCalculationRequest>>({
        mileagePerYear: 12000,
        leaseDuration: 48,
        incomeTaxPercentage: 0.375,
    })

    let ready = false
    if (request.parameterSet
        && request.mileagePerYear !== undefined
        && request.leaseDuration !== undefined
        && request.leaseObject) {
        ready = true
    }

    return {
        parameterSets,
        setParameterSets,
        request,
        setRequest,
        ready,
    }
}

export const PrepareCalculatorProvider: FC = ({ children }) => {
    const context = usePrepareCalculator()
    const auth = useContext(AuthContext)

    useEffect(() => {
        const load = async () => {
            const calculator = new Calculator(auth)
            const pcs = await calculator.listParameterSets()
            context.setParameterSets(pcs)

            context.setRequest({
                ...context.request,
                parameterSet: pcs[0].id,
            })
        }
        load()
    }, [])

    return (
        <PrepareCalculatorContext.Provider value={ context }>
            { children }
        </PrepareCalculatorContext.Provider>
    )
}
