import { RepositoryInterface } from '@repositories/interfaces/Repository'
import { Props } from '@models/domain/QueueStatus/Props'
import { QueueStatus } from '@models/domain/QueueStatus'
import { Connection } from '@services/Connection/Interfaces'

export class QueueStatusRepository implements RepositoryInterface<QueueStatus> {
    public resourceIdentifier = 'queueStatus'

    private connection: Connection

    constructor(connection: Connection) {
        this.connection = connection
    }
    public modelConstructor = (data?: object) => new QueueStatus(data as Props)

    public async get(): Promise<QueueStatus> {
        const response = await this.connection.get({
            path: `/message-queue-status`,
        })
        return this.modelConstructor(response.data as object)
    }
}
