import { PropertyValueNoArray } from '@models/utility/Property'
import { DomainModel } from '@models/domain/interfaces/DomainModel'

export enum Type {
    'INTEGER',
    'FLOAT',
    'STRING',
    'BOOLEAN',
    'DATE',
    'DATETIME',
    'ENUM',
    'JSON',
}

export interface Attribute<T extends DomainModel<T>> {
    key: string,
    type: Type,
    enum?: string[],
    forHumans?: (val?: PropertyValueNoArray) => string
    filter?: (model: T, value: string | number) => boolean
}
