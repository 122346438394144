import Head from 'next/head'
import { SideMenu } from '@organisms/SideMenu'
import TopMenu from '@organisms/TopMenu'
import QueueStatusIndicator from '@organisms/QueueStatusIndicator'
import * as React from 'react'
import * as C from './Style'

interface Props {
    children: object,
    pageTitle: string,
    submenuItemsLeft?: JSX.Element,
    submenuItemsRight?: JSX.Element,
}

export const Default: React.FC<Props> = ({
    children,
    pageTitle,
    submenuItemsLeft,
    submenuItemsRight,
}) => (
    <C.Wrapper>
        <Head>
            <title>{ pageTitle } - leaseDoc</title>
        </Head>
        <SideMenu />
        <C.Content>
            <TopMenu
                submenuItemsLeft={ submenuItemsLeft }
                submenuItemsRight={ submenuItemsRight }
            />
            <C.Main>
                { children }
            </C.Main>
            <C.Footer>
                <p>{ new Date().getFullYear() } &copy; leaseDoc</p>
                <C.QueueStatusIndicatorWrapper>
                    <QueueStatusIndicator />
                </C.QueueStatusIndicatorWrapper>
            </C.Footer>
        </C.Content>
    </C.Wrapper>
)
