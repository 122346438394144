import { FC, useContext, useState } from 'react'
import { PrepareCalculatorContext } from '@contexts/PrepareCalculatorContext'
import { Autocomplete, Label } from '@tblg/components'
import { withTranslation } from 'react-i18next'
import { WithTranslation } from 'next-i18next'
import capitalize from '@helpers/capitalize'
import styled from 'styled-components'
import onClickOutside from 'react-onclickoutside'

const Div = styled.div`
    margin: 0 2rem 0 0;
    display: flex;
    align-items: center;
`

const Span = styled.span`
    cursor: pointer;
`

const StyledLabel = styled(Label)`
    margin: 0 0.5rem 0 0;
`

const Auto = onClickOutside(Autocomplete)

const SelectParameterSetInner: FC<WithTranslation> = ({ t }) => {
    const context = useContext(PrepareCalculatorContext)
    const [editing, setEditing] = useState(false)
    const options =  context.parameterSets.map(p => ({
        label: p.name,
        value: p.id,
    }))
    const getOptions = (q: string) => options
        .filter(o => o.label.toUpperCase().includes(q.toUpperCase()))

    const onChange = (pcsId?: number) => {
        if (pcsId) {
            context.setRequest({
                ...context.request,
                parameterSet: pcsId,
            })
            setEditing(false)
        }
    }

    const edit = () => {
        setEditing(true)
    }

    const cancel = () => {
        setEditing(false)
    }

    return (
        <>
            { options.length > 0 && (
                <Div>
                    <StyledLabel>{ capitalize(t('calculate:request.parameterSet')) + ':' }</StyledLabel>
                    { editing && (
                        <Auto
                            getOptions={ getOptions }
                            options={ options }
                            noneSelectedText={ t('common:none_selected') }
                            value={ context.request.parameterSet }
                            initInputValue={ options.find(o => o.value === context.request.parameterSet)?.label }
                            onChange={ onChange }
                            handleClickOutside={ cancel }
                        />
                    ) }
                    { !editing && (
                        <Span onClick={ edit }>
                            { options.find(o => o.value === context.request.parameterSet)?.label }
                        </Span>
                    ) }
                </Div>
            ) }
        </>
    )
}

export const SelectParameterSet = withTranslation(['common', 'calculate'])(SelectParameterSetInner)
