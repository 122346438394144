import { State as AuthState } from '@services/Auth'
import { SCOPES } from '@constants/scopes'

/**
 * A policy is a function that accepts two parameters: The current auth state containing the authenticated user data,
 * and an optional object containing other parameters that can be used to resolve the policy.
 */
export type Policy = ((auth: AuthState, params?: object) => boolean)

interface Policies {
    [key: string]: Policy,
}

/**
 * Simple policies are policies that check if the authenticated user has a given scope as defined in Auth0. Each
 * policy uses the same key as it's corresponding scope.
 */
const SIMPLE_POLICIES: Policies = Object.keys(SCOPES).reduce((obj: object, key: string) => {
    Object.assign(obj, {
        [key]: (authState: AuthState) => authState.authenticated && authState.scope.includes(SCOPES[key]),
    })
    return obj
}, {})

export const NotPolicy = (policy: Policy): Policy => (auth: AuthState) => !policy(auth)

export const OrPolicy = (policies: Policy[]): Policy => (auth: AuthState) => policies
    .reduce((allowed, policy) => allowed || policy(auth), false as boolean)

export const AndPolicy = (policies: Policy[]): Policy => (auth: AuthState) => policies
    .reduce((allowed, policy) => allowed && policy(auth), true as boolean)

/**
 * Here simple policies can be overwritten. E.g. a policy to check if a user can edit it's own calculations doesn't only
 * check if the user has the correct scope to perform the action, it also checks if the supplied calculation (passed
 * through the params-object) actually belongs to that user.
 * Of course, you can also add entirely new policies.
 */
export const POLICIES: Policies = {
    ...SIMPLE_POLICIES,
}
