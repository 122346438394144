import styled, { css } from 'styled-components'
import { WithThemeProp } from '@tblg/components'

interface Props {
  minimized: boolean,
}

// Style
export const TopBar = styled.div<Props & WithThemeProp>`
  align-items: center;
  background-color: ${props => props.theme.colors.sideBarMenuTopBar };
  box-sizing: border-box;
  display: flex;
  height: 4.8rem;
  justify-content: space-between;
  padding: 0 2rem;
  width: 100%;

  ${props => props.minimized && css`
    padding: 0;
    justify-content: center;

    ${/* sc-sel */BrandTitle} {
      display: none;
    }
  `}
`

export const BrandTitle = styled.h1<WithThemeProp>`
  color: ${props => props.theme.colors.primary};
`

export const Bar = styled.span<WithThemeProp>`
  background-color: ${props => props.theme.colors.sideBarMenuHamburger };
  display: block;
  height: 2px;
  position: relative;
  transform: translateY(0.7rem);
  width: 100%;

  &::before {
    background-color: ${props => props.theme.colors.sideBarMenuHamburger };
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    top: -0.5rem;
    width: 100%;
  }

  &::after {
    background-color: ${props => props.theme.colors.sideBarMenuHamburger };
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    top: 0.6rem;
    width: 100%;
  }
`

export const Hamburger = styled.div<WithThemeProp>`
  height: 2rem;
  transition: all 0.4s ease;
  width: 2rem;

  ${/* sc-sel */Bar}, ${/* sc-sel */Bar}::before, ${/* sc-sel */Bar}::after {
    transition: all 0.4s ease;
  }

  &:hover {
    cursor: pointer;
    ${/* sc-sel */Bar}, ${/* sc-sel */Bar}::before, ${/* sc-sel */Bar}::after {
      background-color: ${props => props.theme.colors.sideBarMenuHamburgerHover };
      transition: all 0.4s ease;
    }
  }
`
