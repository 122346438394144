import { AndPolicy, OrPolicy, POLICIES } from '@constants/policies'
import { ENVIRONMENT } from '@root/environment'
import { Region } from '@constants/regions'

const getLoadRoutes = (target: string) => [
    {
        titleKey: 'menu:general.load.indices',
        route: `/load/${target}/indices`,
        policy: POLICIES.JOBS_VIEW,
    },
    {
        titleKey: 'menu:general.load.jobs',
        route: `/load/${target}/jobs`,
        policy: POLICIES.JOBS_VIEW,
    },
]

export const MENU = [
    {
        titleKey: 'menu:general.dashboard',
        icon: 'home',
        route: '/',
    },
    {
        titleKey: 'menu:general.load.title',
        icon: 'dataStorage',
        policy: POLICIES.JOBS_VIEW,
        subItems: ENVIRONMENT.LEASE_OBJECT_LOADER.TARGETS.length > 1
            ? ENVIRONMENT.LEASE_OBJECT_LOADER.TARGETS.map((target: string) => ({
                titleKey: `common:target.${target}`,
                subItems: getLoadRoutes(target),
            }))
            : getLoadRoutes(ENVIRONMENT.LEASE_OBJECT_LOADER.TARGETS[0]),
    },
    {
        groupTitleKey: 'menu:products.group_title',
    },
    {
        titleKey: 'menu:products.lease_objects.title',
        icon: 'car',
        policy: AndPolicy([
            POLICIES.VIEW_LEASE_OBJECTS,
            OrPolicy([POLICIES.VIEW_CONFIGURABLE_OBJECTS, POLICIES.VIEW_STOCK_OBJECTS]),
        ]),
        subItems: [
            {
                titleKey: 'menu:products.lease_objects.configurable_objects',
                route: '/products/lease-objects/configurable-objects',
                policy: POLICIES.VIEW_CONFIGURABLE_OBJECTS,
            },
            {
                titleKey: 'menu:products.lease_objects.stock_objects',
                route: '/products/lease-objects/stock-objects',
                policy: POLICIES.VIEW_STOCK_OBJECTS,
            },
        ],
    },
    {
        titleKey: 'menu:products.insurances.title',
        icon: 'insurance',
        policy: OrPolicy([POLICIES.VIEW_INSURANCES, POLICIES.VIEW_ADDITIONAL_INSURANCES]),
        subItems: [
            {
                titleKey: 'menu:products.insurances.wa_casco',
                route: '/products/insurances/common',
                policy: POLICIES.VIEW_INSURANCES,
                if: ENVIRONMENT.REGIONS.includes(Region.NL),
            },
            {
                titleKey: 'menu:products.insurances.ba_omnium',
                route: '/products/insurances/common',
                policy: POLICIES.VIEW_INSURANCES,
                if: ENVIRONMENT.REGIONS.includes(Region.BE),
            },
            {
                titleKey: 'menu:products.insurances.additional_insurances',
                route: '/products/insurances/additional-insurances',
                policy: POLICIES.VIEW_ADDITIONAL_INSURANCES,
            },
        ],
    },
    {
        titleKey: 'menu:products.additional_services',
        icon: 'diamond',
        policy: POLICIES.VIEW_ADDITIONAL_SERVICES,
        route: '/products/additional-services',
    },
    {
        titleKey: 'menu:products.tires',
        icon: 'tire',
        policy: POLICIES.VIEW_TIRES,
        route: '/products/tires',
    },
    {
        titleKey: 'menu:products.fuel_cards',
        icon: 'creditCard',
        policy: POLICIES.VIEW_FUEL_CARDS,
        route: '/products/fuel-cards',
    },
    {
        titleKey: 'menu:products.master_tables.title',
        icon: 'database',
        policy: OrPolicy([
            POLICIES.VIEW_MAKES,
            POLICIES.VIEW_MODELS,
            POLICIES.VIEW_FUEL_TYPES,
            POLICIES.VIEW_POWER_TRAINS,
            POLICIES.VIEW_SEGMENTS,
            POLICIES.VIEW_BODY_TYPES,
            POLICIES.VIEW_TRANSMISSION_TYPES,
            POLICIES.VIEW_SUPPLIERS,
            POLICIES.VIEW_SPEED_INDICES,
            POLICIES.VIEW_BATTERY_TYPES,
            POLICIES.VIEW_PAINTS,
            POLICIES.VIEW_COLORS,
            POLICIES.VIEW_OPTION_CATEGORIES,
            POLICIES.VIEW_MAINTENANCE_SEGMENTS,
        ]),
        subItems: [
            {
                titleKey: 'menu:products.master_tables.makes',
                route: '/products/master-tables/makes',
                policy: POLICIES.VIEW_MAKES,
            },
            {
                titleKey: 'menu:products.master_tables.models',
                route: '/products/master-tables/models',
                policy: POLICIES.VIEW_MODELS,
            },
            {
                titleKey: 'menu:products.master_tables.fuel_types',
                route: '/products/master-tables/fuel-types',
                policy: POLICIES.VIEW_FUEL_TYPES,
            },
            {
                titleKey: 'menu:products.master_tables.power_trains',
                route: '/products/master-tables/power-trains',
                policy: POLICIES.VIEW_POWER_TRAINS,
            },
            {
                titleKey: 'menu:products.master_tables.segments',
                route: '/products/master-tables/segments',
                policy: POLICIES.VIEW_SEGMENTS,
            },
            {
                titleKey: 'menu:products.master_tables.body_types',
                route: '/products/master-tables/body-types',
                policy: POLICIES.VIEW_BODY_TYPES,
            },
            {
                titleKey: 'menu:products.master_tables.transmission_types',
                route: '/products/master-tables/transmission-types',
                policy: POLICIES.VIEW_TRANSMISSION_TYPES,
            },
            {
                titleKey: 'menu:products.master_tables.suppliers',
                route: '/products/master-tables/suppliers',
                policy: POLICIES.VIEW_SUPPLIERS,
            },
            {
                titleKey: 'menu:products.master_tables.speed_indices',
                route: '/products/master-tables/speed-indices',
                policy: POLICIES.VIEW_SPEED_INDICES,
            },
            {
                titleKey: 'menu:products.master_tables.battery_types',
                route: '/products/master-tables/battery-types',
                policy: POLICIES.VIEW_BATTERY_TYPES,
            },
            {
                titleKey: 'menu:products.master_tables.paints',
                route: '/products/master-tables/paints',
                policy: POLICIES.VIEW_PAINTS,
            },
            {
                titleKey: 'menu:products.master_tables.colors',
                route: '/products/master-tables/colors',
                policy: POLICIES.VIEW_COLORS,
            },
            {
                titleKey: 'menu:products.master_tables.option_categories',
                route: '/products/master-tables/option-categories',
                policy: POLICIES.VIEW_OPTION_CATEGORIES,
            },
            {
                titleKey: 'menu:products.master_tables.maintenance_segments',
                route: '/products/master-tables/maintenance-segments',
                policy: POLICIES.VIEW_MAINTENANCE_SEGMENTS,
            },
        ],
    },
    {
        groupTitleKey: 'menu:pricing.group_title',
    },
    {
        titleKey: 'menu:pricing.mrt.title',
        icon: 'oil',
        policy: OrPolicy([
            POLICIES.VIEW_MR_PRICE_CORRECTION_MATRICES,
            POLICIES.VIEW_MR_SCORE_FACTORS,
            POLICIES.VIEW_TIRE_SCORE_FACTORS,
        ]),
        subItems: [
            {
                titleKey: 'menu:pricing.mrt.mr_price_correction_tables',
                policy: POLICIES.VIEW_MR_PRICE_CORRECTION_MATRICES,
                route: '/pricing/mrt/mr-price-correction-tables',
            },
            {
                titleKey: 'menu:pricing.mrt.mr_score',
                policy: POLICIES.VIEW_MR_SCORE_FACTORS,
                route: '/pricing/mrt/mr-score',
            },
            {
                titleKey: 'menu:pricing.mrt.tire_score',
                policy: POLICIES.VIEW_TIRE_SCORE_FACTORS,
                route: '/pricing/mrt/tire-score',
            },
            {
                titleKey: 'menu:pricing.mrt.tire_service_life',
                policy: POLICIES.VIEW_SERVICE_LIFE_TIRE_WIDTHS,
                route: '/pricing/mrt/tire-service-life/tire-widths',
            },
        ],
    },
    {
        titleKey: 'menu:pricing.discount_management.title',
        icon: 'discountTag',
        policy: OrPolicy([
            POLICIES.VIEW_CUSTOMERS,
            POLICIES.VIEW_DISCOUNT_RULES,
        ]),
        subItems: [
            {
                titleKey: 'menu:pricing.discount_management.discount_sets',
                route: '/pricing/discount-management/discount-sets',
                policy: POLICIES.VIEW_CUSTOMERS,
            },
            {
                titleKey: 'menu:pricing.discount_management.discount_rules',
                route: '/pricing/discount-management/discount-rules',
                policy: POLICIES.VIEW_DISCOUNT_RULES,
            },
        ],
    },
    {
        titleKey: 'menu:pricing.contribution_margin.title',
        icon: 'discountTag',
        policy: POLICIES.VIEW_CONTRIBUTION_MARGIN_SETS,
        route: '/pricing/contribution-margin',
    },
    {
        titleKey: 'menu:pricing.residual_value_tables',
        icon: 'residualValue',
        policy: POLICIES.VIEW_RESIDUAL_VALUE_CORRECTION_MATRICES,
        route: '/pricing/residual-value-tables',
    },
    {
        titleKey: 'menu:pricing.interest_types',
        icon: 'moneyGrowth',
        policy: POLICIES.VIEW_INTERESTS,
        route: '/pricing/interests',
    },
    {
        titleKey: 'menu:pricing.fuel_prices',
        icon: 'fuelTank',
        policy: POLICIES.VIEW_FUEL_PRICES,
        route: '/pricing/fuel-prices',
    },
    {
        titleKey: 'menu:pricing.road_tax',
        icon: 'traffic',
        policy: POLICIES.VIEW_ROAD_TAXES,
        route: '/pricing/road-tax',
        if: ENVIRONMENT.REGIONS.includes(Region.NL),
    },
    {
        titleKey: 'menu:pricing.road_tax_be_brussels.title',
        icon: 'traffic',
        policy: OrPolicy([POLICIES.VIEW_ROAD_TAXES_BE_BRUSSELS, POLICIES.VIEW_ROAD_TAXES_BE_BRUSSELS_LPG_ADDITIONS]),
        if: ENVIRONMENT.REGIONS.includes(Region.BE),
        subItems: [
            {
                titleKey: 'menu:pricing.road_tax_be_brussels.road_tax',
                route: '/pricing/road-tax-be-brussels/road-tax',
                policy: POLICIES.VIEW_ROAD_TAXES_BE_BRUSSELS,
            },
            {
                titleKey: 'menu:pricing.road_tax_be_brussels.lpg_addition',
                route: '/pricing/road-tax-be-brussels/lpg-addition',
                policy: POLICIES.VIEW_ROAD_TAXES_BE_BRUSSELS_LPG_ADDITIONS,
            },
        ],
    },
    {
        titleKey: 'menu:pricing.bpm.title',
        icon: 'license',
        policy: OrPolicy([
            POLICIES.VIEW_BPM_DEPRECIATIONS,
            POLICIES.VIEW_BPM_DIESEL_SURCHARGES,
            POLICIES.VIEW_BPM_PASSENGER_VEHICLES,
        ]),
        subItems: [
            {
                titleKey: 'menu:pricing.bpm.depreciation',
                policy: POLICIES.VIEW_BPM_DEPRECIATIONS,
                route: '/pricing/bpm/depreciation',
            },
            {
                titleKey: 'menu:pricing.bpm.diesel_surcharge',
                policy: POLICIES.VIEW_BPM_DIESEL_SURCHARGES,
                route: '/pricing/bpm/diesel-surcharge',
            },
            {
                titleKey: 'menu:pricing.bpm.passenger_vehicle',
                policy: POLICIES.VIEW_BPM_PASSENGER_VEHICLES,
                route: '/pricing/bpm/passenger-vehicle',
            },
        ],
    },
    {
        titleKey: 'menu:pricing.delivery_costs',
        icon: 'chevronRightCircle',
        policy: POLICIES.VIEW_DELIVERY_COSTS,
        route: '/pricing/delivery-costs',
    },
    {
        titleKey: 'menu:pricing.parameter_sets',
        icon: 'crop',
        policy: POLICIES.VIEW_PARAMETER_SETS,
        route: '/pricing/parameter-sets',
    },
    {
        titleKey: 'menu:pricing.more_fewers',
        icon: 'chevronRightCircle',
        policy: POLICIES.VIEW_MORE_FEWERS,
        route: '/pricing/more-fewers',
    },
    {
        titleKey: 'menu:pricing.replacement_vehicle.title',
        icon: 'heartPulse',
        policy: OrPolicy([
            POLICIES.VIEW_REPLACEMENT_DESCRIPTIONS,
            POLICIES.VIEW_REPLACEMENT_MILEAGE_TO_DAYS,
            POLICIES.VIEW_REPLACEMENT_PRODUCT_FACTORS,
        ]),
        subItems: [
            {
                titleKey: 'menu:pricing.replacement_vehicle.replacement_descriptions',
                policy: POLICIES.VIEW_REPLACEMENT_DESCRIPTIONS,
                route: '/pricing/replacement-vehicles/replacement-descriptions',
            },
            {
                titleKey: 'menu:pricing.replacement_vehicle.replacement_mileage_to_days',
                policy: POLICIES.VIEW_REPLACEMENT_MILEAGE_TO_DAYS,
                route: '/pricing/replacement-vehicles/replacement-mileage-to-days',
            },
            {
                titleKey: 'menu:pricing.replacement_vehicle.replacement_product_factors',
                policy: POLICIES.VIEW_REPLACEMENT_PRODUCT_FACTORS,
                route: '/pricing/replacement-vehicles/replacement-product-factors',
            },
        ],
    },
    {
        groupTitleKey: 'menu:integrations.group_title',
    },
    {   titleKey: 'menu:integrations.jato.title',
        icon: 'jato',
        policy: OrPolicy([
            POLICIES.JOBS_VIEW,
            POLICIES.VIEW_EXTERNAL_VALUE_MAPPINGS,
            POLICIES.VIEW_UNPROCESSABLE_CONFIGURABLE_OBJECTS,
        ]),
        subItems: [
            {
                titleKey: 'menu:integrations.jato.load',
                route: '/integrations/jato/load',
                policy: POLICIES.JOBS_VIEW,
            },
            {
                titleKey: 'menu:integrations.jato.mapping',
                route: '/integrations/jato/mapping',
                policy: POLICIES.VIEW_EXTERNAL_VALUE_MAPPINGS,
            },
            {
                titleKey: 'menu:integrations.jato.non_stp_data',
                route: '/integrations/jato/non-stp-data',
                policy: POLICIES.VIEW_UNPROCESSABLE_CONFIGURABLE_OBJECTS,
            },
        ],
    },
    {
        titleKey: 'menu:integrations.autotelex.title',
        icon: 'autotelex',
        policy: OrPolicy([
            POLICIES.VIEW_AUTOTELEX_CORRECTIONS,
            POLICIES.JOBS_VIEW,
        ]),
        subItems: [
            {
                titleKey: 'menu:integrations.autotelex.load',
                route: '/integrations/autotelex/load',
                policy: POLICIES.JOBS_VIEW,
            },
            ...(ENVIRONMENT.AUTOTELEX.METHOD === 'ALL' ? [{
                titleKey: 'menu:integrations.autotelex.mapping',
                route: '/integrations/autotelex/mapping',
                policy: POLICIES.VIEW_EXTERNAL_VALUE_MAPPINGS,
            }] : []),
            {
                titleKey: 'menu:integrations.autotelex.residual_values',
                route: '/integrations/autotelex/residual-values',
                policy: POLICIES.VIEW_RESIDUAL_VALUES,
            },
            {
                titleKey: 'menu:integrations.autotelex.corrections',
                route: '/integrations/autotelex/corrections',
                policy: POLICIES.VIEW_AUTOTELEX_CORRECTIONS,
            },
            {
                titleKey: 'menu:integrations.autotelex.trade_value_corrections',
                route: '/integrations/autotelex/trade-value-corrections',
                policy: POLICIES.VIEW_AUTOTELEX_TRADE_VALUE_CORRECTIONS,
            },
        ],
    },
    {   titleKey: 'menu:integrations.api.title',
        icon: 'code',
        policy: POLICIES.VIEW_API_LOG,
        subItems: [
            {
                titleKey: 'menu:integrations.api.log',
                route: '/integrations/api/log',
                policy: POLICIES.VIEW_API_LOG,
            },
        ],
    },
    {
        titleKey: 'menu:integrations.channels',
        icon: 'cloudUpload',
        policy: POLICIES.VIEW_CHANNELS,
        route: '/integrations/channels',
    },
    {
        groupTitleKey: 'menu:system.group_title',
    },
    {
        titleKey: 'menu:system.settings.title',
        icon: 'cog',
        policy: OrPolicy([
            POLICIES.VIEW_CAR_SELECTIONS,
            POLICIES.VIEW_FUEL_MAPPINGS,
            POLICIES.VIEW_MASTER_TRANSLATIONS,
        ]),
        subItems: [
            {
                titleKey: 'menu:system.settings.car_selections',
                route: '/system/settings/car-selections',
                policy: POLICIES.VIEW_CAR_SELECTIONS,
            },
            {
                titleKey: 'menu:system.settings.fuel_mappings',
                route: '/system/settings/fuel-mappings',
                policy: POLICIES.VIEW_FUEL_MAPPINGS,
            },
            {
                titleKey: 'menu:system.settings.master_translations',
                route: '/system/settings/master-translations',
                policy: POLICIES.VIEW_MASTER_TRANSLATIONS,
            },
        ],
    },
]
