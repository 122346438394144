import { MenuElement, WithThemeProp } from '@tblg/components'
import styled, { css } from 'styled-components'

interface Props {
  minimized: boolean,
}

export const SideBarWrapper = styled.div<Props & WithThemeProp>`
  background-color: ${props => props.theme.colors.sideBarMenuBackground };
  flex: 0 0 20rem;
  overflow: hidden;

  ${props => props.minimized && css`
    flex: 0 0 5rem;
    transition: flex 0.4s ease;
  `}

  position: relative;
  transition: flex 0.4s ease;
`

export const MenuWrapper = styled.div`
  padding-top: 4.5rem;
  height: calc(100% - 9.3rem);
  overflow-x: hidden;
  overflow-y: overlay;
  direction: rtl;
  
  &::-webkit-scrollbar {
    background-color: ${ props => props.theme.colors.secondary };
    width: 5px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: ${ props => props.theme.colors.sideBarScrollbar };
  }
  
  ${ MenuElement } {
    direction: ltr;
  }
`
