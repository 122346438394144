import styled from 'styled-components'
import { Icon, IconProps } from '@tblg/components'
import { spin } from '@theme/animations/spin'
import { TrafficRate } from './Enums'

export const TrafficIcon = styled(Icon)<IconProps & { trafficRate?: TrafficRate }>`
  font-weight: bold;
  margin-left: 0.5rem;
  width: 1rem;
  display: inline-block;
  vertical-align: top;
  color: ${ props => {
    switch (props.trafficRate) {
      case TrafficRate.veryHigh:
        return props.theme.colors.error
      case TrafficRate.high:
        return props.theme.colors.warning
      default:
        return props.theme.colors.quaternary
    }
  } };
  
  &:after {
    position: absolute;
    animation: ${spin} ${ props => {
      switch (props.trafficRate) {
        case TrafficRate.veryHigh:
          return '0.5s'
        case TrafficRate.high:
          return '1s'
        default:
          return '1.5s'
      }
    } } linear infinite;
  }
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;
`

export const TechInfo = styled.div`
    font-size: 0.8rem;
    margin-right: 1rem;
`
