import {
    Calculation,
    CalculationRequest,
    MinimalCalculationRequest,
} from '@tblg/calculator-types'
import { State as AuthState } from '@services/Auth'
import { ENVIRONMENT } from '@root/environment'

export interface ParameterSet {
    id: number,
    name: string,
    description: string,
}

export class Calculator {
    constructor(private auth: AuthState) {}

    public calculate = async (request: MinimalCalculationRequest): Promise<{
        request: CalculationRequest,
        calculation: Calculation,
    }> => {
        const response = await fetch(`${ENVIRONMENT.FACADE_V2.URL}/calculation`, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.auth.accessToken}`,
            },
        })

        const result = await response.json()

        return {
            request: result.request,
            calculation: result.calculation,
        }
    }

    public listParameterSets = async (): Promise<ParameterSet[]> => {
        const response = await fetch(`${ENVIRONMENT.FACADE_V2.URL}/cps`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.auth.accessToken}`,
            },
        })
        return await response.json() as ParameterSet[]
    }
}
