import { AuthConsumer, State as AuthState } from '@services/Auth'
import React from 'react'
import { Button } from '@tblg/components'
import * as C from './Style'

interface Props {
  show: boolean,
}

const UserMenu: React.FC<Props> = ({ show }) => {
  return (
    <AuthConsumer>
        { ({ user }: AuthState) => user && (
            <C.Panel show={ show }>
              <C.PanelHeader>
                Welkom, { user.nickname }
              </C.PanelHeader>
              <C.PanelBody/>
              <C.PanelFooter>
                <AuthConsumer>
                  { ({ logout }) => <Button onClick={ logout }>Uitloggen</Button> }
                </AuthConsumer>
              </C.PanelFooter>
            </C.Panel>
        ) }
    </AuthConsumer>
  )
}

export default UserMenu
