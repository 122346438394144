import { DomainModel } from '@models/domain/interfaces/DomainModel'
import { RelationInterface } from '@models/domain/interfaces/relations/Interface'
import { ResourceFilter } from '@models/utility/ResourceFilter'

export class BelongsToMany<T extends DomainModel<T>> extends RelationInterface<T> {

    private totalCount?: number

    constructor(
        key: string,
        model: () => T,
        filter?: ResourceFilter<T>,
        eagerLoad: boolean = false,
        isAliasFor?: string,
    ) {
        super(key, model, filter, eagerLoad, isAliasFor)
    }

    public isBelongsToMany() {
        return true
    }

    public setTotalCount(totalCount: number) {
        this.totalCount = totalCount
    }

    public getTotalCount() {
        if (this.totalCount === undefined) {
            throw new Error('No total count available, query it first')
        }
        return this.totalCount
    }
}
