import {
    Menu,
    MenuGroupTitleInterface,
    MenuItemInterface,
    MenuContext,
    MenuOrientation,
} from '@tblg/components'
import { MENU } from '@constants/menu'
import React, { useContext, useState } from 'react'
import TopBar from './components/TopBar'
import { SideBarWrapper, MenuWrapper } from './Style'
import { MenuGroupTitle as MenuGroupTitleType, Menu as MenuType, MenuItem as MenuItemType } from './Types'
import { AuthContext, Can } from '@services/Auth'
import Router, { withRouter, SingletonRouter } from 'next/router'
import { withTranslation, WithTranslation } from 'next-i18next'
import { WithT } from 'i18next'
import flattenArray from '@helpers/flattenArray'

interface Props extends WithT {
    router: SingletonRouter
}

const SideMenuComponent = ({ router, t }: Props & WithTranslation) => {
    const [ minimizedManually, setMinimizedManually ] = useState(false)
    const [ minimized, setMinimized ] = useState(false)

    const auth = useContext(AuthContext)

    const updateMinimized = () => {
        setMinimizedManually(!minimizedManually)
        setMinimized(!minimizedManually)
    }

    const onMouseEnter = () => {
        if (minimized) {
            setMinimized(false)
        }
    }

    const onMouseLeave = () => {
        if (minimizedManually) {
            setMinimized(true)
        }
    }

    const flattenMenuItems = (items: MenuType): MenuItemType[] => flattenArray(
        items.filter(item => (item as unknown as MenuItemType).titleKey)
            .map(item => {
                const menuItem = item as unknown as MenuItemType
                return [menuItem, ...flattenMenuItems(menuItem.subItems || [])]
            }),
    )

    const mostSpecificMenuRoute = flattenMenuItems(MENU as MenuType)
        .reduce((mostSpecificRoute, current) =>
            current.route && router.pathname.startsWith(current.route)
            && current.route.length > mostSpecificRoute.length
                ? current.route
                : mostSpecificRoute
        , '')

    const menuItemMapper =
        (items: MenuType, can: Can): Array<MenuGroupTitleInterface | MenuItemInterface> =>
            items.filter(item => item.policy === undefined || can(item.policy))
                .filter(item => item.if === undefined || item.if)
                .map(item => {
                if ((item as MenuItemType).titleKey) {
                    const menuItem = item as MenuItemType
                    return {
                        ...menuItem,
                        title: t(menuItem.titleKey),
                        onClick: () => menuItem.route !== undefined ? Router.push(menuItem.route) : null,
                        selected: menuItem.route === mostSpecificMenuRoute,
                        collapsed: !(Array.isArray(menuItem.subItems) && menuItem.subItems
                            .find(subItem => subItem.route === mostSpecificMenuRoute) !== undefined),
                        subItems: menuItem.subItems ?
                            menuItemMapper(menuItem.subItems, can) :
                            undefined,
                    }
                }
                const menuGroupTitle = item as MenuGroupTitleType
                return {
                    ...menuGroupTitle,
                    groupTitle: t(menuGroupTitle.groupTitleKey),
                }
            })

    return (
        <SideBarWrapper minimized={ minimized } >
            <TopBar
                minimized={ minimized }
                updateMinimized={ updateMinimized }
            />
            <MenuWrapper onMouseEnter={ onMouseEnter } onMouseLeave={ onMouseLeave }>
                <Menu
                    context={ MenuContext.mainMenu }
                    items={ menuItemMapper(MENU as MenuType, auth.can) }
                    minimized={ minimized }
                    orientation={ MenuOrientation.vertical }
                />
            </MenuWrapper>
        </SideBarWrapper>
      )
}

export const SideMenu = withRouter(withTranslation(['menu', 'common'])(SideMenuComponent))

export default null
